<template>
  <v-app data-testid="gradient-life-theme">
    <a
      class="sr-only"
      tabindex="0"
      href="#maincontent"
      @click.prevent="scrollToWidget"
      @keydown.enter="scrollToWidget"
    >
      Jump directly to main content
    </a>
    <v-main>
      <v-row justify="center" class="ma-0" dense>
        <v-col cols="12" class="diamond-pattern">
          <v-row class="ma-0" justify="center">
            <v-col cols="12" class="max-theme-width">
              <svg alt="hero clipping path" role="presentation" width="0" height="0">
                <clipPath id="svgClip" clipPathUnits="objectBoundingBox">
                  <path
                    d="M0.75815095,0.0579477769 C0.879893708,0.187288937 0.902165272,0.677587654 0.799370955,0.785996249 C0.627963035,0.966765889 0.26163708,0.91434951 0.111342491,0.755791573 C-0.0332137967,0.603287436 -0.035795248,0.382887577 0.0965066612,0.173955315 C0.200239457,0.0101396315 0.648923894,-0.0580965318 0.75815095,0.0579477769 Z"
                  ></path>
                </clipPath>
              </svg>

              <v-row class="ma-0" align="center">
                <v-col cols="12" md="6" order="2" order-md="1">
                  <div>
                    <img
                      v-if="instance.logo"
                      :src="instance.logo"
                      class="px-3 gradient-logo"
                      alt="hero image of people"
                    />
                    <h1 class="text-h3">
                      <small class="text-h5 text-accent">Helping You Find</small>
                      <br />
                      Life Insurance at a Price that's Right for You
                    </h1>
                    <div class="mt-9">
                      <v-btn
                        variant="tonal"
                        class="text-none hidden-xs"
                        :color="primary"
                        size="large"
                        @click="scrollToWidget"
                      >
                        Get a Quote
                      </v-btn>
                      <v-btn
                        variant="tonal"
                        class="text-none ml-2 hidden-xs"
                        :color="accent"
                        size="large"
                        :href="contactHref"
                      >
                        Talk to your Agent
                      </v-btn>
                      <v-btn
                        variant="tonal"
                        class="text-none hidden-sm-and-up"
                        :color="primary"
                        size="large"
                        block
                        @click="scrollToWidget"
                      >
                        Get a Quote
                      </v-btn>
                      <v-btn
                        variant="tonal"
                        class="text-none mt-1 hidden-sm-and-up"
                        :color="accent"
                        :href="contactHref"
                        size="large"
                        block
                      >
                        Talk to your Agent
                      </v-btn>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="6" order="1" order-md="2" class="text-center nice-elevation">
                  <img
                    data-testid="hero-image"
                    class="gradient-hero"
                    :src="heroImageUrl"
                    alt="happy family"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-0" justify="center">
            <v-col cols="12" class="max-theme-width margin-bottom-5">
              <v-row class="ma-0">
                <v-col cols="12" class="mb-5">
                  <v-row class="ma-0" align="center" justify="center">
                    <div class="bg-primary hidden-md-and-down title-bar"></div>
                    <h2 class="text-h3 text-center text-primary mx-3">Life Insurance Simplified</h2>
                    <div class="bg-primary hidden-md-and-down title-bar"></div>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <marketing-point
                    flat
                    max-width="30rem"
                    role="button"
                    tabindex="0"
                    aria-label="Shop and compare the rates of several highly rated companies at your leisure without the pressure from salespeople."
                    text="Shop and compare the rates of several highly rated companies at your leisure without the pressure from salespeople."
                    :icon="mdiCartOutline"
                    @click="scrollToWidget"
                  />
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <marketing-point
                    flat
                    max-width="30rem"
                    role="button"
                    tabindex="0"
                    aria-label="Plans for every budget and life stage. Once you have selected the plan that is right for you, just complete and sign the application. It's that easy!"
                    text="Plans for every budget and life stage. Once you have selected the plan that is right for you, just complete and sign the application. It's that easy!"
                    :icon="mdiCash"
                    @click="scrollToWidget"
                  />
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <marketing-point
                    flat
                    max-width="30rem"
                    role="link"
                    aria-label="Contact a licensed professional at any time if you prefer."
                    text="If you prefer, you can contact a licensed professional at any time at"
                    :icon="mdiAccountOutline"
                    :href="contactHref"
                    :href-text="phone"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" :style="{ background }" class="px-0 gradient-cutout">
          <div class="hidden-sm-and-down w-100">
            <svg
              class="gradient-cutout-item top"
              role="presentation"
              width="100%"
              height="80"
              viewBox="0 0 500 160"
              alt="sloping cutout"
              preserveAspectRatio="none"
            >
              <path d="M 0,0 L 0,80 Q 250,40 500,160 L 500,0 Z" />
            </svg>
            <svg
              role="presentation"
              class="gradient-cutout-item bottom"
              width="100%"
              height="80"
              viewBox="0 0 500 160"
              preserveAspectRatio="none"
              alt="sloping cutout"
            >
              <path d="M 0,0 L 0,80 Q 250,40 500,160 L 500,0 Z" />
            </svg>
          </div>
          <v-layout id="maincontent" class="gradient-content">
            <v-row class="ma-0" justify="center" align="center">
              <v-card
                id="quote-and-apply"
                elevation="8"
                max-width="75rem"
                width="100%"
                class="my-9 mx-3"
              >
                <router-view :active-layout-component="activeLayoutComponent" />
              </v-card>
            </v-row>
          </v-layout>
        </v-col>
        <v-col cols="12" class="max-theme-width theme-padding">
          <v-row class="ma-0 px-3">
            <v-col cols="12" md="6">
              <v-icon :icon="mdiCheckCircleOutline" :color="accent" size="52" />
              <h3 class="text-h4 text-left text-primary px-1 my-4">
                Our Quote &amp; Apply Tool Makes It Easy
              </h3>

              <ul class="ml-4 pa-1 text-body-1 text-medium-emphasis spaced-line-height">
                <li>Instantly receive Life Insurance quotes from over 30 carriers</li>
                <li>Complete an online application</li>
                <li>Sign the agreement with DocuSign</li>
                <li>Securely receive the policy from respective insurance carriers</li>
              </ul>
            </v-col>
            <v-col cols="12" md="6" class="media-min-height youtube-video"> </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="!hideAdditionalServices"
          id="additional-services"
          data-testid="additional-services"
          cols="12"
          class="theme-padding"
        >
          <v-row justify="center" class="ma-0">
            <v-col cols="12" class="max-theme-width">
              <v-row>
                <v-col cols="12" class="mb-5">
                  <v-row class="ma-0" align="center" justify="center">
                    <div class="bg-primary hidden-md-and-down title-bar"></div>
                    <h2 class="text-h3 text-center text-primary mx-3">Additional Services</h2>
                    <div class="bg-primary hidden-md-and-down title-bar"></div>
                  </v-row>
                </v-col>
                <v-col cols="12" class="mb-9">
                  <div class="text-center text-body-1 text-medium-emphasis spaced-line-height">
                    In addition to our life insurance, we offer several products that require more
                    specialized servicing by our experienced professionals. For inquiries regarding
                    these products, please contact us by calling
                    <a
                      class="text-primary"
                      :href="contactHref"
                      v-text="phone"
                    />.
                  </div>
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <line-of-insurance :url="annuityImg" text="Annuities" object-position="65% 0" />
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <line-of-insurance
                    :url="disabilityImg"
                    text="Disability Income Insurance"
                    object-position="40% 0"
                  />
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <line-of-insurance
                    :url="ltcImg"
                    text="Long Term Care Insurance"
                    object-position="40% 0"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <app-resources />
    </v-main>
  </v-app>
</template>

<script setup>
import AppResources from '#src/AppResources.vue';
import ThemedWidgetLayout from '#src/layouts/ThemedWidgetLayout.vue';
import chroma from 'chroma-js';
import MarketingPoint from '#src/themes/shared/MarketingPoint.vue';
import LineOfInsurance from '#src/themes/shared/LineOfInsurance.vue';

import annuityImg from '#src/assets/themes/life-theme/default-annuities.webp';
import disabilityImg from '#src/assets/themes/life-theme/default-disability.webp';
import ltcImg from '#src/assets/themes/life-theme/default-ltc.webp';
import gradientLifeHero from '#src/assets/themes/life-theme/default.webp';

import { inject, markRaw, onMounted, nextTick } from 'vue';

import { useTheme } from 'vuetify';
import { mdiCheckCircleOutline, mdiCartOutline, mdiCash, mdiAccountOutline } from '@mdi/js';

import { useInstanceSettingsStore } from '#src/stores/instance-settings';

const pinia = inject('pinia');

const activeLayoutComponent = markRaw(ThemedWidgetLayout);
const instance = useInstanceSettingsStore(pinia);

const theme = useTheme();

let heroImageUrl;
if (instance.theme.hero_image_url) {
  heroImageUrl = instance.theme.hero_image_url;
} else {
  heroImageUrl = gradientLifeHero;
}

const hideAdditionalServices = instance.theme.hide_additional_services;

const { primary, accent } = theme.current.value.colors;

let secondaryChroma;
const primaryChroma = chroma(primary);
const isDark = primaryChroma.get('lab.l') < 70;
if (isDark) {
  secondaryChroma = primaryChroma.brighten(2).saturate();
} else {
  secondaryChroma = primaryChroma.darken(2);
}

const bgColors = chroma.scale([primaryChroma.hex(), secondaryChroma.hex()]).colors(6);

const background = `linear-gradient(90deg, ${bgColors.join(', ')})`;

instance.isInternallyEmbedded = true;
instance.use_modal = false;
instance.use_webapp = false;
const phone = instance.phone;
const contactHref = `tel:${phone}`;

function scrollToWidget() {
  const id = 'quote-and-apply';
  const yOffset = -10;
  const element = document.getElementById(id);
  if (!element) return;
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
}

function injectYouTubeVideo() {
  const youtubeVideo = document.createElement('iframe');
  youtubeVideo.width = '100%';
  youtubeVideo.height = '100%';
  youtubeVideo.src = 'https://www.youtube.com/embed/KfaPQ4nuDX0?si=ZibNeNp3MaEsQcU4';
  youtubeVideo.title = 'Quote & Apply For Life Insurance';
  youtubeVideo.frameborder = '0';
  youtubeVideo.allow =
    'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share';

  youtubeVideo.setAttribute('frameborder', '0');
  youtubeVideo.setAttribute('allowfullscreen', true);
  document.querySelector('.youtube-video').appendChild(youtubeVideo);
}

function lazyLoadYouTubeVideo() {
  const ytVideo = document.querySelector('.youtube-video');
  const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      injectYouTubeVideo();
      observer.disconnect();
    }
  });

  if (ytVideo) observer.observe(ytVideo);
  else injectYouTubeVideo();
}

onMounted(() => {
  if (!import.meta.env.SSR) nextTick(() => lazyLoadYouTubeVideo());
});
</script>

<style lang="scss">
// use encodeURIComponent to inline SVG
.diamond-pattern {
  background-image: url("data:image/svg+xml,%3Csvg id='diamond-pattern' width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpattern id='a' patternUnits='userSpaceOnUse' width='20' height='20' patternTransform='scale(4) rotate(45)' %3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='hsla(0, 0%25, 100%25, 1)' /%3E%3Cpath d='M3.25 10h13.5M10 3.25v13.5' stroke-linejoin='round' stroke-linecap='round' stroke-width='0.5' stroke='hsla(0, 0%25, 98%25, 1)' fill-opacity='0' /%3E%3C/pattern%3E%3C/defs%3E%3Crect width='800%25' height='800%25' transform='translate(0,0)' fill='url(%23a)' fill-opacity='1' /%3E%3C/svg%3E%0A");
}

.gradient-logo {
  object-fit: contain;
  max-width: 250px;
  width: auto;
  height: 100px;
}

.gradient-hero {
  clip-path: url(#svgClip);
  object-fit: cover;
  object-position: 65% 0;
}

@media only screen and (max-width: 600px) {
  .gradient-hero {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (min-width: 600px) {
  .gradient-hero {
    width: 400px;
    height: 400px;
  }
}

@media only screen and (min-width: 768px) {
  .gradient-hero {
    width: 500px;
    height: 500px;
  }
}

#widget-chat-parent {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 21;
}

#additional-services {
  background-color: #fafafa;
}

.theme-padding {
  padding: 5rem 0 !important;
}

.max-theme-width {
  max-width: 75rem;
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

.title-bar {
  width: 8rem;
  height: 1px;
}

.gradient-cutout {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  position: relative;

  .gradient-content {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .gradient-cutout-item {
    fill: white;
    position: absolute;
  }

  .gradient-cutout-item.top {
    transform: rotate(180deg) scaleX(-1);
    bottom: -8px;
  }

  .gradient-cutout-item.bottom {
    top: -8px;
    transform: scaleX(-1);
  }
}

.spaced-line-height {
  line-height: 1.5em;
}

.media-min-height {
  min-height: 450px;
}
</style>
